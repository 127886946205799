<template>
  <div id="chart">
    <apexchart
      class="chart-component"
      type="area"
      height="350"
      :options="chartOptions"
      :width="screenWidth"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      aTrans: [],
      series: [
        // {
        //   name: "Entradas",
        //   data: [],
        // },
        // {
        //   name: "Salidas",
        //   data: [],
        // },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
            type: "x",
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "int",
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + " Kg";
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(2) + " Kg";
              }
              return y;
            },
            format: "Kg",
          },
          z: {
            formatter: undefined,
          },
        },
      },
    };
  },
  updated() {
    // this.getItem();
  },
  beforeMount() {
    this.$store.commit("setsShowFor", 1);

    this.getItem();
  },
  created() {
    window.addEventListener("resize", this.widthChart);
    this.widthChart();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.widthChart);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.widthChart();
      } else {
        this.screenWidth = window.innerWidth - 130;
      }
    },
    widthChart() {
      if (this.bSidebar) {
        this.screenWidth = window.innerWidth - 435;
      } else {
        this.screenWidth = window.innerWidth - 215;
      }
    },
    getItem() {
      this.aTrans = [];
      if (this.aTransitionIn !== null) {
        this.aTrans.push(this.aTransitionIn);
      }
      if (this.aTransitionOut !== null) {
        this.aTrans.push(this.aTransitionOut);
      }
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory/inout`,
        {
          params: {
            iPeriod: this.sShowFor,
            sInOut: this.aTrans.length > 1 ? null : this.aTrans[0],
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        }
      )
        .then((response) => {
          let sIn = this.aTransition.find((element) => element == "in");
          let sOut = this.aTransition.find((element) => element == "out");

          const newSeries = [
            {
              name: "Entrada",
              data: sIn !== undefined ? response.data.results.In : [],
            },
            {
              name: "Salidas",
              data: sOut !== undefined ? response.data.results.Out : [],
            },
          ];
          this.series = newSeries;

          const newSeries4 = response.data.results.Periods;

          this.chartOptions.xaxis.categories = newSeries4;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
            this.Error(error.response.data);
        });
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    aTransition() {
      return this.$store.state.aTransition;
    },
    aTransitionIn() {
      return this.$store.state.aTransitionIn;
    },
    aTransitionOut() {
      return this.$store.state.aTransitionOut;
    },
    sShowFor() {
      return this.$store.state.sShowFor;
    },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    refreshTable: function () {
      this.getItem();
    },
    aTransition() {
      this.getItem();
    },
    aTransitionIn() {
      this.getItem();
    },
    aTransitionOut() {
      this.getItem();
    },
    sShowFor() {
      this.getItem();
    },
    bSidebar() {
      this.widthChart();
    },
  },
};
</script>
<style scoped>
.chart-component {
  /* margin-right: 10px !important; */
}
</style>